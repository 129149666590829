
import { defineComponent } from "vue";
import { useRoute } from "vue-router";
import { numberFormat } from "@/core/helpers/currency-helper";
import useInvocationDetail from "@/core/services/compositions/invocation/useInvocationDetail";

export default defineComponent({
  components: {},
  setup() {
    const route = useRoute();

    const invocationId = route.params.id;

    const { data } = useInvocationDetail(invocationId);

    return {
      data,
      numberFormat
    };
  },
  methods: {}
});
